$(document).ready(function() {
  iconMenuPrincipal();
  sliderMultiplesClientes();
  menuAgil();
  starTooltips();
  $(window).resize(function() {
    sliderMultiplesClientes();
  })
})

const starTooltips = () => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
}

const sliderMultiplesClientes = () => {
  // $('#myCarousel').carousel({
  //   interval: 1000
  // })

  $('#sliderAliados.carousel .carousel-item').each(function() {
    let next = $(this).next();
  
    if (($(window).width() < 992)){
      let minPerSlide = 4;
      if (!next.length) {
        next = $(this).siblings(':first');
      }
      next.children(':first-child').clone().appendTo($(this));

      for (let i = 0; i < minPerSlide; i++) {
        next = next.next();
        if (!next.length) {
            next = $(this).siblings(':first');
        }

        next.children(':first-child').clone().appendTo($(this));
      }
    } else {
      let minPerSlide = 5;
      if (!next.length) {
        next = $(this).siblings(':first');
      }
      next.children(':first-child').clone().appendTo($(this));

      for (let i = 0; i < minPerSlide; i++) {
        next = next.next();
        if (!next.length) {
            next = $(this).siblings(':first');
        }

        next.children(':first-child').clone().appendTo($(this));
      }
    }

  });
}

const iconMenuPrincipal = () => {
  const iconMenuPrincipal = document.querySelector('#icon-menu-principal');
  const collapseMenuPrincipal = document.querySelector('#navbarMainMenu');

  collapseMenuPrincipal.addEventListener('show.bs.collapse', () => {
    iconMenuPrincipal.classList.remove('icon-menu');
    iconMenuPrincipal.classList.add('icon-close');
  });

  collapseMenuPrincipal.addEventListener('hide.bs.collapse', () => {
    iconMenuPrincipal.classList.remove('icon-close');
    iconMenuPrincipal.classList.add('icon-menu');
  });
}

const menuAgil = () => {
  const btnMenuAgil = document.querySelector('#btn-menu-agil');
  const collapseMenuAgil = document.querySelector('#collapseMenuAgil');

  collapseMenuAgil.addEventListener('show.bs.collapse', () => {
    btnMenuAgil.classList.add('d-none');
  });

  collapseMenuAgil.addEventListener('hide.bs.collapse', () => {
    btnMenuAgil.classList.remove('d-none');
  });
}